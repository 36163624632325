import { Container, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
// import logo from './Emily_website.jpg';

function App() {
  return <Container className='container_a'>
    <Container className='smaller-container shadow fade-in'>
    <img src="./Emily_website.jpg" alt="A picture of Emily" width="150px" className="shadow"/>
    <br /><br />
    <h1 className="rainbow-text">Hi, I'm Emily Chomicz!</h1>
    <Row>
    <Col xs={4} className="w-100">
    <Button href="mailto:hello@emilychomicz.com" variant="outline-dark" className='my-1 fade-in hover-animation w-100'>Email Me (hello@emilychomicz.com)</Button>
    </Col>
    </Row>
    </Container>
    
  </Container>;
}

export default App;
